<template>
    <form @submit="$event.preventDefault()">
        <div class="pb_10">
            <div
                class="poll-border bl-theme-interactive br_2 c_p mb_10"
                v-for="field in radioList"
                :class="{ active: value.select.value === field.value }"
            >
                <label class="checkbox_item checker_inline">
                    <div class="p_14">
                        <input
                            :name="formData.name"
                            type="radio"
                            :value="field"
                            :checked="value.select.value === field.value"
                            @change="value = field"
                        >
                        <span class="checker_item mt_14 ml_14 br_all"></span>
                        <span class="checkbox_item_title">{{ field.label }} млн руб.</span>
                    </div>
                </label>
            </div>
        </div>

        <div v-if="error" class="alert alert-danger">{{ error }}</div>

        <div class="d_f">
            <button
                v-if="!isFirst"
                @click="$store.commit('prev')"
                class="p_15 bgr_f5f5f5 name_color d_i_f mr_10 br_2 btn-svg-item-2"
            >
                <div class="">
                    <svg fill="#212121" class="w_24">
                        <use xlink:href="#arrowback-24"/>
                    </svg>
                </div>
                <div class="ml_15 fs_16 lh_24 fw_m hidden-xs pr_5">Назад</div>
            </button>
            <button
                :disabled="!isValid"
                @click="getResult"
                class="p_15 bgr_f5f5f5 name_color btn-svg-item br_2 pl_20 d_f "
            >
                <div class="fs_16 lh_24 fw_m ">{{ isLast ? 'Показать результат' : 'Следующий вопрос' }}</div>
                <div class=" ml_15 ">
                    <svg fill="currentColor" class="w_24">
                        <use xlink:href="#arrowforward-24"/>
                    </svg>
                </div>
            </button>
        </div>
    </form>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';

  const prices = (document.body.getAttribute('data-region') === 'spb') ? [0, 1, 7, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100] : [0, 1, 2, 3, 5, 7, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  const defaultValue = {
    select: {
      label: null,
      value: null,
      price: 0
    },
    query: {
      method: 'to', // or 'from'
      prices: [],
    }
  };

  export default {
    name: 'price-form-radio',
    data() {
      return {
        radioList: [],
      }
    },
    computed: {
      ...mapGetters({
        formData: 'getForm',
        isLast: 'isLast',
        isFirst: 'isFirst',
      }),
      ...mapState({
        form: 'form',
        step: 'step',
        loaderPrice: 'loaderPrice',
        minRange: 'minRange',
        maxRange: 'maxRange',
        error: 'error',
        result: 'result'
      }),
      value: {
        get() {
          let value = this.form[this.formData.name];

          if (!value || Array.isArray(value) || !value.select) {
            return {...defaultValue};
          }

          return value;
        },
        set(value) {
          this.changeValue(value)
        }
      },
      isValid() {
        return this.value && this.value.select.price > 0;
      }
    },
    created() {
      this.$store.dispatch('getRangePrices', 'price').then(data => {
        if (!data || !Array.isArray(data)) {
          this.clearValue();
          return false;
        }

        let [ min ] = data;

        let minPrice = Math.min(...[...prices].filter(price => price >= min));
        let rangePrices = [...prices].filter(price => price >= minPrice).splice(0, 2);

        this.radioList.push(...rangePrices.map(price => ({
          label: `До ${price}`,
          value: `to-${price}`,
          price
        })));

        let maxPrice = Math.max(...rangePrices);

        this.radioList.push({
          label: `Более ${maxPrice}`,
          value: `from-${maxPrice}`,
          price: maxPrice
        });

        if (this.isValid) {
          let item = this.radioList.find(({ value }) => value === this.value.select.value);

          if (!item) {
            this.clearValue();
          }
        }
      });
    },
    methods: {
      getResult() {
        this.$store.dispatch('getTotal').then(() => {
          if (this.result) {
            this.$store.commit('next');
          }
        });
      },

      clearValue() {
        this.$store.commit('setValue', {
          name: this.formData.name,
          value: {...defaultValue}
        });
      },

      changeValue(value) {
        let method = value.value.indexOf('to') !== -1 ? 'to' : 'from';
        let rangePrices = [];

        let values = [...prices].filter(price => price < value.price).reverse();

        if (method === 'to') {
          rangePrices.push(...values.map(price => [price, value.price]));
        } else {
          rangePrices.push(...values);
        }

        this.$store.commit('setValue', {
          name: this.formData.name,
          value: {...defaultValue, select: value, query: {
            method,
            prices: rangePrices
          }}
        });
      }
    }
  }
</script>